@SemiBold: 500;
@boldColor: #333;
@marginHorizontal: 24px;
@footButtonHeight: 92px;
@mainColor: #3399ff;
@appBgColor: #f2f2f2;
@splitColor: #f2f2f2;

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  background-color: #f5f5f9;
}
.paddingHorizontal(@size: @marginHorizontal) {
  padding-left: @size;
  padding-right: @size;
}
.Flex(@row: center, @column: center) {
  display: flex;
  align-items: @row;
  justify-content: @column;
}

.lineEllipsis(@row: 1) {
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: @row;
  overflow: hidden;
  white-space: pre-line;
}

.SingleLine() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.listViewSeparator {
  height: 24px;
}

.alreadyBottom {
  text-align: center;
  color: #999;
  height: 60px;
  line-height: 60px;
  font-size: 28px;
}

.defaultPage {
  width: 100%;
  height: calc(100vh - 88px - 120px);
  overflow: hidden;
  flex-direction: column;
  .Flex();

  .defaultImg {
    width: 252px;
    height: 184px;
  }

  .defaultContent {
    margin-top: 40px;
    color: #999999;
    font-size: 30px;
  }
}

:global {
  .am-pull-to-refresh-down .am-pull-to-refresh-indicator {
    margin-top: -45px !important;
  }

  .amap-logo {
    display: none;
    opacity: 0 !important;
  }

  .amap-copyright {
    opacity: 0;
  }

  .am-progress-outer {
    background-color: #03192a;
    display: block;
    height: 20px;
    border-radius: 10px;
    margin-bottom: 32px;

    .am-progress-bar {
      background-image: linear-gradient(269deg, #28d0ff 0%, #006f89 97%);
      border-radius: 10px;
      height: 100% !important;
      border: none !important;
    }
  }

  .am-textarea-item {
    .paddingHorizontal();

    .am-textarea-label {
      color: #333;
      font-size: 30px;
      width: 135px;
      margin-right: 24px;
      line-height: 40px;
      transform: translateY(10px);
    }

    .am-textarea-control textarea {
      font-size: 28px;
      line-height: 40px;
    }
  }

  .am-list-item {
    .am-list-line {
      .am-list-content {
        font-size: 28px;
        color: #888;
      }

      .am-list-extra {
        font-size: 28px;
        color: #888;
      }

      .am-list-arrow {
        width: 21px;
        height: 21px;
      }
    }
  }

  .am-accordion .am-accordion-item .am-accordion-header {
    color: #333;
    font-size: 30px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .am-list-item .am-list-line .am-list-content {
    color: #333;
    font-size: 30px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .am-accordion .am-accordion-item .am-accordion-header i {
    top: 36px;
  }

  .am-list-item.am-input-item {
    height: 96px;
    .paddingHorizontal();

    .am-list-line {
      .am-input-label {
        color: #333;
        font-size: 30px;
        width: 135px;
        margin-right: 24px;
        line-height: 40px;
        // transform: translateY(10px);
      }

      .am-input-control input {
        font-size: 28px;
        line-height: 40px;
      }
    }
  }

  .am-popover-item {
    font-size: 28px;
    color: #666666;
  }

  .am-popover-item-content {
    padding: 17px 36px;
  }

  .am-popover .am-popover-item-container {
    height: auto;
  }

  .am-picker-col-item {
    font-size: 28px;
  }

  .am-picker-popup-item {
    font-size: 28px;
    height: auto;
  }

  .am-modal-alert-content {
    font-size: 28px;
  }

  .am-modal-button-group-v .am-modal-button {
    font-size: 28px;
    padding: 8px;
    height: auto;
  }

  .am-modal-button-group-h .am-modal-button {
    font-size: 28px;
    padding: 8px;
    height: auto;
  }

  .am-modal-transparent {
    width: 540px;
    border-radius: 16px;
    overflow: hidden;
  }

  .am-modal-transparent .am-modal-content {
    padding-top: 42px;
  }

  .am-modal-title {
    font-size: 34px;
    font-weight: @SemiBold;
  }

  .am-modal-header {
    padding-bottom: 36px;
  }

  .am-modal-transparent .am-modal-content .am-modal-body {
    padding: 0 30px 41px 30px;
  }

  .am-modal-alert-content {
    font-size: 28px;
    color: #666;
  }

  // .am-modal-footer {
  //   height: 87px;
  // }

  .am-modal-button-group-h {
    height: 100%;
  }

  .am-modal-button-group-h .am-modal-button {
    .Flex();
  }

  html:not([data-scale]) .am-list-item:not(:last-child) .am-list-line::after {
    height: 0;
  }

  .am-list-item:not(:last-child) .am-list-line {
    border-bottom: 0px;
  }

  .am-accordion .not-expand .am-accordion-header i {
    display: none;
  }

  .am-toast-notice-content .am-toast-text.am-toast-text-icon .am-toast-text-info {
    font-size: 28px;
  }

  .am-toast-notice-content .am-toast-text {
    font-size: 28px;
  }

  html:not([data-scale]) .am-list-item:not(:last-child) .am-list-line::after {
    height: 0;
  }

  .am-list-item:not(:last-child) .am-list-line {
    border-bottom: 0px;
  }

  .am-accordion .not-expand .am-accordion-header i {
    display: none;
  }

  .am-toast-notice-content .am-toast-text.am-toast-text-icon .am-toast-text-info {
    font-size: 28px;
  }

  .am-textarea-count {
    font-size: 24px;
  }

  .am-picker-popup {
    border-radius: 36px 36px 0px 0px;
    height: 632px;

    .am-picker-popup-content {
      height: inherit;

      .am-picker-popup-body {
        height: 100%;

        div {
          height: 100%;

          .am-picker-popup-header {
            height: 80px;
            font-size: 28px;

            .am-picker-popup-item {
              padding: 0 36px;
            }

            .am-picker-popup-header-left {
              color: #000000;
            }

            &::after {
              display: none;
            }
          }

          .am-picker {
            .am-picker-col {
              .am-picker-col-indicator {
                height: 90px;
              }

              .am-picker-col-content {
                .am-picker-col-item {
                  height: 90px;
                  line-height: 90px;
                  font-size: 36px;
                  color: #222222;
                }
              }
            }
          }
        }
      }
    }
  }
}

.page-container {
  width: 100%;
  height: calc(100vh - 88px);
  overflow: hidden;

  .container-content {
    width: 100%;
    height: calc(100vh - 88px - 96px);
    overflow: auto;
  }

  :global {
    .list-view-section-body {
      padding: 0 !important;
    }
  }
}
