html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  background-color: #f5f5f9;
}
.listViewSeparator {
  height: 24px;
}
.alreadyBottom {
  text-align: center;
  color: #999;
  height: 60px;
  line-height: 60px;
  font-size: 28px;
}
.swiper-wrap {
  width: 100%;
  min-height: 800px;
  overflow: hidden;
  position: relative;
  :global {
    .am-carousel {
      height: 800px !important;
    }
    .slider-frame {
      height: 800px !important;
    }
    .slider-decorator-0 {
      bottom: 64px !important;
    }
  }
  .img {
    height: 800px;
    width: 640px;
    display: block;
    margin: auto;
  }

}
.defaultPage {
  width: 100%;
  height: calc(100vh - 88px - 96px);
  overflow: hidden;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.defaultPage .defaultImg {
  width: 520px;
  height: 446px;
}
.defaultPage .defaultContent {
  position: relative;
  top: -100px;
  color: #999999;
  font-size: 26px;
}
.defaultOldPage {
  width: 100%;
  height: calc(100vh - 88px - 290px);
  overflow: hidden;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.defaultOldPage .defaultImg {
  width: 520px;
  height: 446px;
}
.defaultOldPage .defaultContent {
  position: relative;
  top: -100px;
  color: #999999;
  font-size: 26px;
}
:global .amap-logo {
  display: none;
  opacity: 0 !important;
}
:global .amap-copyright {
  opacity: 0;
}
:global .am-progress-outer {
  background-color: #03192a;
  display: block;
  height: 20px;
  border-radius: 10px;
  margin-bottom: 32px;
}
:global .am-progress-outer .am-progress-bar {
  background-image: linear-gradient(269deg, #28d0ff 0%, #006f89 97%);
  border-radius: 10px;
  height: 100% !important;
  border: none !important;
}
:global .am-textarea-item {
  padding-left: 24px;
  padding-right: 24px;
}
:global .am-textarea-item .am-textarea-label {
  color: #333;
  font-size: 30px;
  width: 135px;
  margin-right: 24px;
  line-height: 40px;
  transform: translateY(10px);
}
:global .am-textarea-item .am-textarea-control textarea {
  font-size: 28px;
  line-height: 40px;
}
:global .am-list-item .am-list-line .am-list-content {
  font-size: 28px;
  color: #888;
}
:global .am-list-item .am-list-line .am-list-extra {
  font-size: 28px;
  color: #888;
}
:global .am-list-item .am-list-line .am-list-arrow {
  width: 21px;
  height: 21px;
}
:global .am-accordion .am-accordion-item .am-accordion-header {
  color: #333;
  font-size: 30px;
  padding-top: 24px;
  padding-bottom: 24px;
}
:global .am-list-item .am-list-line .am-list-content {
  color: #333;
  font-size: 30px;
  padding-top: 24px;
  padding-bottom: 24px;
}
:global .am-accordion .am-accordion-item .am-accordion-header i {
  top: 36px;
}
:global .am-list-item.am-input-item {
  height: 96px;
  padding-left: 24px;
  padding-right: 24px;
}
:global .am-list-item.am-input-item .am-list-line .am-input-label {
  color: #333;
  font-size: 30px;
  width: 135px;
  margin-right: 24px;
  line-height: 40px;
}
:global .am-list-item.am-input-item .am-list-line .am-input-control input {
  font-size: 28px;
  line-height: 40px;
}
:global .am-popover-item {
  font-size: 28px;
  color: #666666;
}
:global .am-popover-item-content {
  padding: 17px 36px;
}
:global .am-popover .am-popover-item-container {
  height: auto;
}
:global .am-picker-col-item {
  font-size: 28px;
}
:global .am-picker-popup-item {
  font-size: 28px;
  height: auto;
}
:global .am-modal-alert-content {
  font-size: 28px;
}
:global .am-modal-button-group-v .am-modal-button {
  font-size: 28px;
  padding: 8px;
  height: auto;
}
:global .am-modal-button-group-h .am-modal-button {
  font-size: 28px;
  padding: 8px;
  height: auto;
}
:global .am-modal-transparent {
  width: 540px;
  border-radius: 16px;
  overflow: hidden;
}
:global .am-modal-transparent .am-modal-content {
  padding-top: 42px;
}
:global .am-modal-title {
  font-size: 34px;
  font-weight: 500;
}
:global .am-modal-header {
  padding-bottom: 36px;
}
:global .am-modal-transparent .am-modal-content .am-modal-body {
  padding: 0 30px 41px 30px;
}
:global .am-modal-alert-content {
  font-size: 28px;
  color: #666;
}
:global .am-modal-button-group-h {
  height: 100%;
}
:global .am-modal-button-group-h .am-modal-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
:global html:not([data-scale]) .am-list-item:not(:last-child) .am-list-line::after {
  height: 0;
}
:global .am-list-item:not(:last-child) .am-list-line {
  border-bottom: 0px;
}
:global .am-accordion .not-expand .am-accordion-header i {
  display: none;
}
:global .am-toast-notice-content .am-toast-text.am-toast-text-icon .am-toast-text-info {
  font-size: 28px;
}
:global .am-toast-notice-content .am-toast-text {
  font-size: 28px;
}
:global html:not([data-scale]) .am-list-item:not(:last-child) .am-list-line::after {
  height: 0;
}
:global .am-list-item:not(:last-child) .am-list-line {
  border-bottom: 0px;
}
:global .am-accordion .not-expand .am-accordion-header i {
  display: none;
}
:global .am-toast-notice-content .am-toast-text.am-toast-text-icon .am-toast-text-info {
  font-size: 28px;
}
:global .am-textarea-count {
  font-size: 24px;
}
:global .am-picker-popup {
  border-radius: 36px 36px 0px 0px;
  height: 632px;
}
:global .am-picker-popup .am-picker-popup-content {
  height: inherit;
}
:global .am-picker-popup .am-picker-popup-content .am-picker-popup-body {
  height: 100%;
}
:global .am-picker-popup .am-picker-popup-content .am-picker-popup-body div {
  height: 100%;
}
:global .am-picker-popup .am-picker-popup-content .am-picker-popup-body div .am-picker-popup-header {
  height: 80px;
  font-size: 28px;
}
:global .am-picker-popup .am-picker-popup-content .am-picker-popup-body div .am-picker-popup-header .am-picker-popup-item {
  padding: 0 36px;
}
:global .am-picker-popup .am-picker-popup-content .am-picker-popup-body div .am-picker-popup-header .am-picker-popup-header-left {
  color: #000000;
}
:global .am-picker-popup .am-picker-popup-content .am-picker-popup-body div .am-picker-popup-header::after {
  display: none;
}
:global .am-picker-popup .am-picker-popup-content .am-picker-popup-body div .am-picker .am-picker-col .am-picker-col-indicator {
  height: 90px;
}
:global .am-picker-popup .am-picker-popup-content .am-picker-popup-body div .am-picker .am-picker-col .am-picker-col-content .am-picker-col-item {
  height: 90px;
  line-height: 90px;
  font-size: 36px;
  color: #222222;
}
.page-container {
  width: 100%;
  height: calc(100vh - 88px);
  overflow: hidden;
}
.page-container .container-content {
  width: 100%;
  height: calc(100vh - 88px - 96px);
  overflow: auto;
}
.page-container-search {
  width: 100%;
  height: calc(100vh - 88px - 96px);
  overflow: hidden;
}
.page-container-search .container-content {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.showSFTBSMD {
  padding: 30px 24px 14px;
  color: #000;
  font-size: 32px;
  font-weight: 500;
}
.inputCount {
  padding-left: 24px;
  padding-right: 24px;
  font-size: 24px;
  color: #c7c7c7;
  display: flex;
  justify-content: flex-end;
}
.headerStyle {
  position: relative;
  width: 100%;
  height: 88px;
  background: #fff;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #333;
  border-bottom: 1px solid #eeeeee;
}
.headerStyle .headerBackView {
  z-index: 2;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 38px;
}
.headerStyle .headerBackView .gobackIcon {
  width: 48px;
  height: 48px;
  fill: black;
}
.headerStyle .titleView {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  font-size: 36px;
  color: #333;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerStyle .selectTitleView {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  font-size: 36px;
  color: #333;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerStyle .selectTitleView select {
  background-color: #df3326;
  border: none;
  color: #fff;
}
.headerStyle .selectMain {
  position: absolute !important;
  bottom: -150px;
  border: 1px solid #979797;
  background-color: #fff;
  color: #333;
  text-align: center;
  font-size: 32px;
  padding: 10px 39px;
  border-radius: 12px;
}
.headerStyle .selectArrow {
  display: inline-block;
  border-width: 10px;
  border-style: solid;
  position: absolute;
  left: 480px;
}
.headerStyle .actionStyle {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  height: 100%;
}
.headerStyle :global .am-list-item {
  background-color: #df3326 !important;
  text-align: center !important;
}
.headerStyle :global .am-list-item .am-list-line {
  margin: auto;
  display: block;
  position: relative;
  text-align: right !important;
  padding-right: 30px;
}
.headerStyle :global .am-list-item .am-list-line .am-list-extra {
  display: inline;
  color: #fff !important;
  text-align: center;
}
.headerStyle :global .am-list-item .am-list-line .am-list-arrow {
  display: none;
}
.headerStyle :global .am-list-item .am-list-line::after {
  content: '';
  position: absolute;
  top: 23px;
  color: #fff;
  border-width: 10px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
  margin-left: 10px;
}
.headerStyle :global .am-list-item .am-list-line:hover::after {
  top: 14px;
  border-color: transparent transparent #fff transparent;
}
.headerStyle :global .am-search {
  position: relative;
  display: flex;
  align-items: center;
  height: 68px;
  margin: auto 32px;
  overflow: hidden;
  background-color: #fff;
  width: 100%;
  height: 100%;
}
.headerStyle :global .am-search .am-search-input {
  flex: 1 1;
  position: relative;
  width: 100%;
  height: 80%;
  overflow: hidden;
  background-color: #efeff4;
  background-clip: padding-box;
  border-radius: 34px;
}
.headerStyle :global .am-search .am-search-input .am-search-synthetic-ph {
  height: 100%;
  padding-left: 16px;
  width: auto !important;
}
.headerStyle :global .am-search .am-search-input .am-search-synthetic-ph .am-search-synthetic-ph-container {
  display: flex;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  height: 100%;
}
.headerStyle :global .am-search .am-search-input .am-search-synthetic-ph-icon {
  height: 36px;
  width: 36px;
  background-size: 36px 36px;
  margin-right: 16px;
}
.headerStyle :global .am-search .am-search-input .am-search-synthetic-ph-placeholder {
  height: 100%;
  font-size: 26px;
  color: #bbbbbb;
  letter-spacing: 0;
  display: contents;
}
.headerStyle :global .am-search .am-search-input input[type='search'] {
  height: 100%;
  font-size: 26px;
  letter-spacing: 0;
}
.headerStyle :global .am-search .am-search-input .am-search-clear {
  height: 32px;
  width: 32px;
  margin-right: 10px;
  padding: 19px 0;
  background-size: 100%;
}
.headerStyle :global .am-search .am-search-cancel {
  margin-left: 32px;
  font-size: 30px;
  color: #666666;
  letter-spacing: 0;
}
.headerStyle :global .am-search .am-search-start .am-search-input input[type='search'] {
  padding: 0 40px;
}
.headerStyle :global .am-search.am-search-start .am-search-input input[type='search'] {
  padding: 0 70px;
  height: 100%;
  font-size: 26px;
  letter-spacing: 0;
}
.noBoxShadow {
  border-bottom: none;
}
.tabs :global .am-tabs-tab-bar-wrap {
  height: 96px;
}
.tabs :global .am-tabs-tab-bar-wrap .am-tabs-default-bar-content {
  align-items: center;
}
.tabs :global .am-tabs-tab-bar-wrap .am-tabs-default-bar-content .am-tabs-default-bar-tab {
  height: 100%;
  font-size: 30px;
}
.tabs :global .am-tabs-tab-bar-wrap .am-tabs-default-bar-content .am-tabs-default-bar-tab-active:after {
  position: absolute;
  left: 50%;
  bottom: 13px;
  border-radius: 3px;
  width: 36px;
  height: 5px;
  transform: translateX(-50%);
}
.tabs :global .am-tabs-tab-bar-wrap .am-tabs-default-bar-content .am-tabs-default-bar-underline {
  display: none;
}
.listView :global .am-list-body {
  background: transparent;
}
.listView :global .am-list-body:after {
  content: none !important;
}
.avatarContainer {
  width: 76px;
  height: 76px;
  border-radius: 50%;
  background: #ccc;
}
.button {
  height: 56px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  color: #666;
  font-size: 28px;
  border: 1px solid #ccc;
  border-radius: 8px;
}
.buttonContent {
  width: calc(100vw - 24px * 2);
  height: 92px;
  background: #df3326;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #fff;
  border-radius: 12px;
}
.commonButton {
  background: #f2f2f2;
  width: 100%;
  text-align: center;
}
.commonButtonContent {
  width: calc(100vw - 24px * 2);
  height: 92px;
  background: #df3326;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #fff;
  border-radius: 12px;
}
.footerButton {
  position: fixed;
  background: #f2f2f2;
  z-index: 10;
  left: 0;
  bottom: 0;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  padding-bottom: 24px;
}
.footerButtonContent {
  width: calc(100vw - 24px * 2);
  height: 92px;
  background: #df3326;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #fff;
  border-radius: 12px;
}
.addIcon {
  width: 52px;
  height: 52px;
}
.appendButton {
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
}
.appendButtonContent {
  height: 92px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #000;
}
.footerButtonGroup {
  width: calc(100vw - 12px * 2);
  position: fixed;
  display: flex;
  z-index: 10;
  left: 0;
  bottom: 0;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 24px;
}
.footerButtonGroupContent {
  flex: 1 1;
  height: 92px;
  background: #df3326;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #fff;
  border-radius: 12px;
}
.button-theme-red {
  border-color: #df3326;
  background-color: #df3326;
  color: #ffffff;
}
.imageListView {
  display: flex;
  flex-wrap: wrap;
}
.header-tab-container {
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #df3326;
}
.header-tab-container .headerBackView {
  z-index: 2;
  height: 100%;
  position: fixed;
  left: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-tab-container .headerBackView .gobackIcon {
  width: 48px;
  height: 48px;
  fill: white;
}
.header-tab-container-content {
  background: #fff;
  overflow: hidden;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 2px solid #fff;
}
.header-tab-container-content-item {
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 22px;
  padding-right: 20px;
  font-size: 28px;
  color: #fff;
  background: #df3326;
}
.header-tab-container-content-item:not(:last-child) {
  border-right: 2px solid #fff;
}
.header-tab-container-content-item-active {
  color: #df3326;
  background: #fff;
}
