html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  background-color: #f5f5f9;
}
.listViewSeparator {
  height: 24px;
}
.alreadyBottom {
  text-align: center;
  color: #999;
  height: 60px;
  line-height: 60px;
  font-size: 28px;
}
.defaultPage {
  width: 100%;
  height: calc(100vh - 88px - 120px);
  overflow: hidden;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.defaultPage .defaultImg {
  width: 252px;
  height: 184px;
}
.defaultPage .defaultContent {
  margin-top: 40px;
  color: #999999;
  font-size: 30px;
}
:global .am-pull-to-refresh-down .am-pull-to-refresh-indicator {
  margin-top: -45px !important;
}
:global .amap-logo {
  display: none;
  opacity: 0 !important;
}
:global .amap-copyright {
  opacity: 0;
}
:global .am-progress-outer {
  background-color: #03192a;
  display: block;
  height: 20px;
  border-radius: 10px;
  margin-bottom: 32px;
}
:global .am-progress-outer .am-progress-bar {
  background-image: linear-gradient(269deg, #28d0ff 0%, #006f89 97%);
  border-radius: 10px;
  height: 100% !important;
  border: none !important;
}
:global .am-textarea-item {
  padding-left: 24px;
  padding-right: 24px;
}
:global .am-textarea-item .am-textarea-label {
  color: #333;
  font-size: 30px;
  width: 135px;
  margin-right: 24px;
  line-height: 40px;
  transform: translateY(10px);
}
:global .am-textarea-item .am-textarea-control textarea {
  font-size: 28px;
  line-height: 40px;
}
:global .am-list-item .am-list-line .am-list-content {
  font-size: 28px;
  color: #888;
}
:global .am-list-item .am-list-line .am-list-extra {
  font-size: 28px;
  color: #888;
}
:global .am-list-item .am-list-line .am-list-arrow {
  width: 21px;
  height: 21px;
}
:global .am-accordion .am-accordion-item .am-accordion-header {
  color: #333;
  font-size: 30px;
  padding-top: 24px;
  padding-bottom: 24px;
}
:global .am-list-item .am-list-line .am-list-content {
  color: #333;
  font-size: 30px;
  padding-top: 24px;
  padding-bottom: 24px;
}
:global .am-accordion .am-accordion-item .am-accordion-header i {
  top: 36px;
}
:global .am-list-item.am-input-item {
  height: 96px;
  padding-left: 24px;
  padding-right: 24px;
}
:global .am-list-item.am-input-item .am-list-line .am-input-label {
  color: #333;
  font-size: 30px;
  width: 135px;
  margin-right: 24px;
  line-height: 40px;
}
:global .am-list-item.am-input-item .am-list-line .am-input-control input {
  font-size: 28px;
  line-height: 40px;
}
:global .am-popover-item {
  font-size: 28px;
  color: #666666;
}
:global .am-popover-item-content {
  padding: 17px 36px;
}
:global .am-popover .am-popover-item-container {
  height: auto;
}
:global .am-picker-col-item {
  font-size: 28px;
}
:global .am-picker-popup-item {
  font-size: 28px;
  height: auto;
}
:global .am-modal-alert-content {
  font-size: 28px;
}
:global .am-modal-button-group-v .am-modal-button {
  font-size: 28px;
  padding: 8px;
  height: auto;
}
:global .am-modal-button-group-h .am-modal-button {
  font-size: 28px;
  padding: 8px;
  height: auto;
}
:global .am-modal-transparent {
  width: 540px;
  border-radius: 16px;
  overflow: hidden;
}
:global .am-modal-transparent .am-modal-content {
  padding-top: 42px;
}
:global .am-modal-title {
  font-size: 34px;
  font-weight: 500;
}
:global .am-modal-header {
  padding-bottom: 36px;
}
:global .am-modal-transparent .am-modal-content .am-modal-body {
  padding: 0 30px 41px 30px;
}
:global .am-modal-alert-content {
  font-size: 28px;
  color: #666;
}
:global .am-modal-button-group-h {
  height: 100%;
}
:global .am-modal-button-group-h .am-modal-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
:global html:not([data-scale]) .am-list-item:not(:last-child) .am-list-line::after {
  height: 0;
}
:global .am-list-item:not(:last-child) .am-list-line {
  border-bottom: 0px;
}
:global .am-accordion .not-expand .am-accordion-header i {
  display: none;
}
:global .am-toast-notice-content .am-toast-text.am-toast-text-icon .am-toast-text-info {
  font-size: 28px;
}
:global .am-toast-notice-content .am-toast-text {
  font-size: 28px;
}
:global html:not([data-scale]) .am-list-item:not(:last-child) .am-list-line::after {
  height: 0;
}
:global .am-list-item:not(:last-child) .am-list-line {
  border-bottom: 0px;
}
:global .am-accordion .not-expand .am-accordion-header i {
  display: none;
}
:global .am-toast-notice-content .am-toast-text.am-toast-text-icon .am-toast-text-info {
  font-size: 28px;
}
:global .am-textarea-count {
  font-size: 24px;
}
:global .am-picker-popup {
  border-radius: 36px 36px 0px 0px;
  height: 632px;
}
:global .am-picker-popup .am-picker-popup-content {
  height: inherit;
}
:global .am-picker-popup .am-picker-popup-content .am-picker-popup-body {
  height: 100%;
}
:global .am-picker-popup .am-picker-popup-content .am-picker-popup-body div {
  height: 100%;
}
:global .am-picker-popup .am-picker-popup-content .am-picker-popup-body div .am-picker-popup-header {
  height: 80px;
  font-size: 28px;
}
:global .am-picker-popup .am-picker-popup-content .am-picker-popup-body div .am-picker-popup-header .am-picker-popup-item {
  padding: 0 36px;
}
:global .am-picker-popup .am-picker-popup-content .am-picker-popup-body div .am-picker-popup-header .am-picker-popup-header-left {
  color: #000000;
}
:global .am-picker-popup .am-picker-popup-content .am-picker-popup-body div .am-picker-popup-header::after {
  display: none;
}
:global .am-picker-popup .am-picker-popup-content .am-picker-popup-body div .am-picker .am-picker-col .am-picker-col-indicator {
  height: 90px;
}
:global .am-picker-popup .am-picker-popup-content .am-picker-popup-body div .am-picker .am-picker-col .am-picker-col-content .am-picker-col-item {
  height: 90px;
  line-height: 90px;
  font-size: 36px;
  color: #222222;
}
.page-container {
  width: 100%;
  height: calc(100vh - 88px);
  overflow: hidden;
}
.page-container .container-content {
  width: 100%;
  height: calc(100vh - 88px - 96px);
  overflow: auto;
}
.page-container :global .list-view-section-body {
  padding: 0 !important;
}
.myContent {
  width: 100%;
  height: 100%;
  position: relative;
}
.myContent .approval-query-search-wrapper {
  height: 88px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 23px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.myContent .approval-query-search-wrapper img {
  width: 64px;
  height: 64px;
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.myContent .approval-query-search-wrapper .approval-query-cancel {
  width: 109px;
  padding-left: 24px;
  font-size: 30px;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #3399ff;
}
.myContent .approval-query-search-wrapper :global .am-list-item {
  display: inline-block;
  width: calc(100% - 109px);
  height: 64px;
  box-sizing: border-box;
  border: none;
  box-shadow: 0px 1px 0px 0px #e6e6e6;
  border-radius: 6px;
  padding-left: 55px;
  font-size: 30px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 36px;
}
.myContent .approval-query-search-wrapper :global .am-list-item .am-list-line {
  height: 64px;
}
.myContent .approval-query-search-wrapper :global .am-icon-cross-circle-o {
  position: absolute;
  right: 119px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 24px;
  width: 24px;
}
.myContent .approval-query-search-wrapper :global .am-list-line::after {
  background-color: #fff !important;
}
.myContent :global .Common__tabs___3K7L7 {
  height: 100% !important;
  overflow: scroll !important;
}
.myContent :global .Common__tabs___3K7L7::-webkit-scrollbar {
  display: none;
}
.myContent :global .am-list-view-scrollview {
  height: 100% !important;
  overflow: scroll !important;
}
.myContent :global .am-list-view-scrollview::-webkit-scrollbar {
  display: none;
}
.myContent :global .am-tabs-default-bar-tab {
  color: #333333 !important;
  background: #fff !important;
}
.myContent :global .am-tabs-default-bar-tab-active {
  color: #3399ff !important;
  font-weight: 600;
}
.myContent :global .am-tabs-default-bar-tab::after {
  height: 0 !important;
}
.myContent :global .am-tabs-default-bar-tab-active:after {
  width: 36px !important;
  height: 5px !important;
  background: #3399ff !important;
}
.myContent :global .am-tabs-default-bar-top {
  background: transparent !important;
}
.searchList {
  width: 100%;
  height: calc(100vh - 120px - 88px);
}
.card {
  margin: 30px 24px 0;
  width: 702px;
  background: #fff;
  position: relative;
  border-radius: 24px;
}
.card > div:not(:last-child) {
  margin-bottom: 20px;
}
.card .contentItem {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.card .contentItem .contentItemTop {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1Px solid #ddd;
}
.card .contentItem .contentItemTop .contentItemTopLeft {
  height: 200px;
  width: 160px;
  border-radius: 12px;
  flex: 0 0 auto;
  margin: 24px;
}
.card .contentItem .contentItemTop .contentItemTopRight {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 12px;
}
.card .contentItem .contentItemTop .contentItemTopRight .title {
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  white-space: pre-line;
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 8px;
  color: #333333;
}
.card .contentItem .contentItemTop .contentItemTopRight .des {
  font-size: 30px;
  line-height: 50px;
  color: #333333;
}
.card .contentItem .contentItemTop .contentItemTopRight .des span {
  color: #999999;
}
.card .contentItem .contentItemBottom {
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  white-space: pre-line;
  font-size: 30px;
  line-height: 42px;
  margin: 24px;
  color: #333333;
}
